@import '../../config.scss';

.becomeMerchant {
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	width: 330px;

	@include respond-to(md) {
		width: 600px;
	}

	p {
		color: #232;
		font-weight: 400;
	}

	form {
		display: grid;
		grid-template-columns: 1fr;
	}

	.number {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.8rem;
	}

	.time {
		margin: 1rem 0;
	}
}
