@import '../../config.scss';

.retarget {
	&__container {
		@include container;
	}

	&__table {
		min-width: 1200px;
		background-color: white;
	}

	&__header {
		@include table-head-row;
	}

	&__body {
		@include table-body-row;
		@include tag;

		p {
			color: #585858;
			line-height: 1.4rem;
		}
	}
}
