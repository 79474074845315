@import '../../config.scss';

.account {
	&__container {
		@include container;
	}

	&__header {
		@include table-head-row;
	}

	&__table {
		min-width: 1200px;
		background-color: white;

		.negetive {
			background: linear-gradient(to bottom, #80808048, white);
		}
	}

	&__body {
		@include table-body-row;
	}

	&__pagination {
		padding: 2rem 0;
		display: flex;
		justify-content: flex-end;
	}
}
