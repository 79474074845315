@import '../../config.scss';

.breakdown {
	&__container {
		@include container;
	}

	&__search {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 1rem 0;
		gap: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid #06468133;
	}

	&__main {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;
		section {
			display: grid;
			gap: 2rem;
			grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		}
	}

	&__stats {
		display: grid;
		gap: 1rem;
		grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
		perspective: 900px;
		&Item {
			transform: translateX(-10px);
			padding: 1rem;
			border: 1px solid #0000001a;
			background: linear-gradient(-45deg, #0b6d6d31, transparent);
		}
	}

	&__table {
		min-width: 1200px;
		background-color: white;
		&Header {
			@include table-head-row;
		}
	}

	&__body {
		@include table-body-row;

		@include tag;

		p {
			color: #585858;
		}
	}

	&__datePicker {
		position: absolute;
		top: 100px;
		left: 40px;
		z-index: 100;
	}
}
