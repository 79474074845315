.merchantProfile {
	&__main {
		gap: 1.5rem;
		.deem {
			color: grey;
		}

		.editBtn {
			transform: translateY(-12px);
		}
	}
}

.select {
	margin: 1rem 0;
}

.MuiTimelineItem-missingOppositeContent:before {
	display: none;
}
