@import '../../config.scss';

.deliveryReqTable {
	&__header {
		@include table-head-row;
		&Col {
			h4 {
				font-size: medium;
				font-weight: 500;
			}
		}
	}

	&__body {
		&Main {
			@include table-body-row;
		}

		@include tag;

		.hr24 {
			background-color: rgba(255, 194, 194, 0.466);
		}
		.hr48 {
			background-color: #3aacf82f;
		}
	}
}
