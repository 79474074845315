@import "./config.scss";

// resets

html,
body {
  overflow-x: hidden;
  @include respond-to(md) {
    display: unset;
  }
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;

  .warn {
    background-color: rgba(255, 0, 0, 0.185);
  }
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;

  padding: 0;
  margin: 0;
}

body {
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $bg-color;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.css-26l3qy-menu {
  z-index: 1000 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  color: #0a9e48dd;
  &:hover {
    text-decoration: underline;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// overflow

// svg
.MuiSvgIcon-root {
  cursor: pointer;
}

.overflow {
  overflow-x: auto;
}

// select

.select {
  &:first-child {
    width: 100%;
  }

  text-align: left;
  font-size: small;
}

// flex

.flex {
  display: flex;
  align-items: center;
  :nth-of-type(odd) {
    margin-left: 0.4rem;
  }
}

.text-center {
  text-align: center;
}

//

.table-min {
  min-width: 1200px;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  min-height: 100vh;
  padding: 1.5rem;
}

@media (max-width: 768px) {
  .container {
    padding: 0.8rem;
  }
}

// Toastify

.Toastify {
  &__toast-container {
    width: auto;
  }
  &__toast-body {
    width: 100%;
  }
  &__progress-bar--animated {
    background: #494848;
    height: 0;
  }
}

// header space

.top-space {
  margin-top: 120px;
}

// rounded

.rounded {
  border-radius: 6px;
}

// datepicker

.date {
  border: 1px solid #d9d9d9;
  padding: 0.1rem 0;
}

// colors

.info {
  color: crimson;
}
.success {
  color: green;
}

// cursor

.pointer {
  cursor: pointer;
}

// loading

.loader {
  img {
    position: absolute;
    z-index: 10000;
    top: 35vh;
    left: 35vw;
  }
}

// issue

.issue {
  $color: red;
  color: $color !important;
  background-color: transparent;
  border: 1px solid $color;
  display: inline-block;
  padding: 0.5rem;
  font-size: x-small;
  cursor: pointer;
}

.error {
  color: red !important;
}

// status code color

$paid-delivered: #06ac06;

.created {
  background-color: rgb(77, 77, 77);
}
.cancelled {
  background-color: red;
}
.picked_up {
  background-color: #5902bd;
}
.nearest_hub {
  background-color: orange;
}
.rider_assigned {
  background-color: rgb(3, 17, 94);
}
.picked_up_bag {
  background-color: orange;
}
.delivered {
  color: white !important;
  background-color: $paid-delivered;
}
.pending {
  background-color: orange;
}
.returned,
.returned_paid_by_customer {
  background-color: #d10639;
}
.rescheduled {
  background-color: #79003294;
}
.partially_delivered {
  background-color: orange;
}

.waiting {
  background-color: #a82d00;
}

.rejected {
  background-color: #ff1515;
}

.paid {
  background-color: $paid-delivered;
  color: white !important;
}

.unpaid {
  background-color: #ff440009;
  color: orangered !important;
  border: 1px solid orangered;
}

// animation

.anim-card {
  opacity: 0;
  animation: anim-card ease-out 0.15s forwards;
  transform: translateX(-20px) scale(1);

  @for $nth from 1 to 30 {
    &:nth-of-type(#{$nth}) {
      animation-delay: #{$nth * 0.07}s;
    }
  }

  @keyframes anim-card {
    from {
      opacity: 0;
      transform: translateX(-20px) scale(1);
    }
    to {
      opacity: 1;
      transform: translateX(-0px) scale(1);
    }
  }
}

.anim-frame {
  opacity: 0;
  animation: anim-frame ease-out 0.5s forwards;
  // transform: scale(1.3);

  @keyframes anim-frame {
    from {
      opacity: 0;
      // transform: scale(1.3);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.anim-fade {
  opacity: 0;
  animation: anim-fade ease-in-out 1.5s forwards;

  @keyframes anim-fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

// datepicker border

div[title="date"] {
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
}

// scrollbar

::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #575555a6;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, 0.74);
}

.rdrDateRangePickerWrapper {
  flex-direction: column-reverse;
}

@include respond-to(md) {
  .rdrDateRangePickerWrapper {
    flex-direction: row;
  }
}
