@import '../../config.scss';

.pickupAssign {
	&__container {
		@include container;
	}

	&__assign {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		&__each {
			flex: 1 1 230px;
			margin: 0 0.5rem;
			display: flex;
			align-items: center;

			&__title {
				font-size: large;
				transform: translateY(-1.5rem);
				margin-right: 0.6rem;
				flex-basis: 50px;
			}

			&__select {
				flex: 1;
			}
		}

		.btn {
			display: flex;
			transform: translateY(-1.5rem);
			justify-content: center;
			:first-child {
				width: 50%;
			}
		}
	}

	&__brandTable {
		display: none;
		@include respond-to(lg) {
			display: block;
		}
	}

	&__brandCard {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;
		perspective: 900px;

		@include respond-to(lg) {
			display: none;
		}
	}

	&__search {
		margin-bottom: 3rem;
	}
}
