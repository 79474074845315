.tabsContainer{
    background-color: #060c21;
    padding-right: 4%;
}
.tabs{
    background-color: #060c21;
}
.createBtn{
    color: #fff;
    border-color: #fff;
}
.tabs button{
    color: #fff;
}