@import '../../config.scss';

.riders {
	&__container {
		@include container;
	}

	&__search {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 1rem 0;
		gap: 1rem;
	}

	&__table {
		min-width: 1200px;
		background-color: white;
	}

	&__header {
		@include table-head-row;
	}

	&__body {
		// @include table-body-row;
		span {
			width: 300px !important;
		}

		p {
			color: #585858;
		}
	}
}
.editBtn{
	span{
		width: auto;
		height: auto;
		background-color: transparent;
		color: #fff;
		border-radius: inherit;
		padding: 0;
	}
	height: auto;
}
