@import '../../config.scss';

.merchantOtp {
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	width: 330px;

	@include respond-to(md) {
		width: 450px;
	}
	strong {
		letter-spacing: 1px;
	}

	p {
		color: #232;
		font-weight: 400;
	}

	form {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
		gap: 1rem;
		@include respond-to(md) {
			grid-template-columns: repeat(4, 100px);
			padding: 2rem;
		}
		input {
			padding: 1rem;
			border-radius: 6px;
			font-size: x-large;
			outline: none;

			@include respond-to(md) {
				padding: 2rem;
			}
		}
	}
}
