@import '../../config.scss';

.paymentDetails {
	min-height: 110vh;
	&__body {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1.5rem;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		.search {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			.date {
				margin: 0 0.5rem;
			}

			&__datePicker {
				position: absolute;
				top: 50px;
				// left: -300px;
				z-index: 100;

				@include respond-to(md) {
					left: -400px;
				}
			}
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 1rem;
		justify-content: space-between;
		.left {
			button:nth-of-type(1) {
				margin-right: 1rem;
			}
		}

		.right {
			flex: 1;
			& > * {
				float: right;
			}
		}

		button {
			width: 100%;
			margin: 1rem 0;

			@include respond-to(sm) {
				width: 200px;
			}
		}
	}
}
