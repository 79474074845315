@import '../../config.scss';

.common {
	min-height: 100vh;
	display: grid;
	grid-template-columns: 1fr;
	line-height: 1.8rem;
	text-align: center;

	@include respond-to(md) {
		grid-template-columns: 1fr 1fr;
		text-align: left;
	}

	&__logo {
		background-position: center;
		background-color: $dark-color;
		background-repeat: no-repeat;
		background-size: 300px;
		display: none;

		@include respond-to(md) {
			display: block;
		}
	}

	&__logo-mobile {
		@include respond-to(md) {
			display: none;
		}
	}

	&__child {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: 1rem;

		@include respond-to(md) {
			padding: 6rem;
			flex-direction: row;
			justify-content: flex-start;
		}
	}
}
