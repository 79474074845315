@import '../../config.scss';

.reset {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	width: 330px;

	@include respond-to(md) {
		width: 400px;
	}

	form {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;
	}
}
