@import '../../config.scss';

.allSchemas {
	&__container {
		@include container;
		button {
			margin: 0 0 1rem auto;
		}
	}

	&__table {
		min-width: 1200px;
		background-color: white;
	}

	&__header {
		@include table-head-row;
	}

	&__body {
		@include table-body-row;

		p {
			color: #585858;
		}
	}
}
