@import '../../config.scss';

.orderDetails {
	&__main {
		gap: 1.5rem;
	}

	.timeline {
		&__img {
			width: 100%;
			transform: 0;
			margin-bottom: 0;
			@include respond-to(md) {
				margin-bottom: 10rem;
				transform: translateY(100px) scale(2);
			}
		}
	}
}
