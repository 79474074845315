@import '../../config.scss';

.createParcel {
	&__main {
		background-color: $bg-color !important;
		gap: 1.5rem;

		@include respond-to(md) {
			height: 260vh;
		}
	}

	.checkbox {
		border-radius: 6px;
		border: 1px solid #c0bfbf;
		padding: 0 0.3rem;
	}

	.section {
		& > * {
			margin: 1rem 0;
		}
		.select {
			z-index: 1000;
		}

		.group {
			& > * {
				margin: 0.5rem 0;
			}
		}

		.outlined {
			border: 1px solid #9e9fa0;
			padding: 0.5rem;
		}
	}

	.calculator {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;

		grid-column: 1/3;

		.estimation {
			top: 100px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.total {
			border-top: 1px solid grey;
			padding-top: 1rem;
		}
	}

	.fields {
		border: 1px solid gray;
		background: white;
		@include respond-to(md) {
			border: 0;
			margin-top: 0;
			// background: linear-gradient(to bottom, white, #ffffff00);
		}
	}

	.stick {
		position: relative;
		border: 1px solid gray;
		position: fixed;
		top: 10;
		transition: 0.2s ease-out;
		transform: translateY(-18px);
		right: -250px;
		z-index: 100;
		background: white;

		@include respond-to(md) {
			transform: translateY(-4px);
			border: 0;
			position: sticky;
			top: 100px;
		}

		.toggle {
			transition: 0.2s ease-out all;
			position: absolute;
			left: -30px;
			color: #fff;
			height: 40px;
			font-weight: 800;
			width: 40px;
			border-radius: 50%;
			background: linear-gradient(45deg, lightblue, rgb(1, 1, 92));
			outline: none;
			border: none;
			font-size: x-large;
			box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
			@include respond-to(md) {
				display: none;
			}
		}
	}

	.open-calc {
		right: 20px;
	}

	.active {
		.toggle {
			background: linear-gradient(45deg, lightgreen, rgb(1, 42, 13));
			animation: roll 0.3s ease-out forwards;
		}

		@keyframes roll {
			0%,
			100% {
				left: -30px;
			}
			50% {
				left: -100px;
			}
		}
	}

	form.grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 0.5rem;
		margin: 1rem 0;
	}
}
