

/* .container{
} */
.container h5{
    font-size: 18px;
    margin: 8px 0;
}
.container p{
    font-size: 18px;
    margin: 8px 0;
}
.card{
    padding: 10px;
}
.avatar{
    margin-bottom: 20px;
}