@import '../../config.scss';

.commonBlock {
	@include container;
	min-height: 30vh;
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;

	&__main {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		gap: 2rem;
		.each {
			padding: 1rem;
			display: grid;
			align-items: center;
			justify-items: start;
		}

		.sales {
			position: relative;
			padding: 2rem;
			display: grid;
			grid-template-columns: 1fr 1fr;

			.main {
				display: grid;
				grid-template-columns: 1fr;
				align-content: flex-start;
				font-size: 1.2rem;

				img {
					position: absolute;
					bottom: 2rem;
					left: 2rem;
				}
			}

			.separate {
				align-self: center;
				.each {
					padding: 1rem;
					margin: 0.5rem 0;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
				}
				&--1 {
					background-color: #f6fff7;
					border: 1px solid #28a552;
				}
				&--2 {
					background-color: #f6fdff;
					border: 1px solid #5fd6fc;
				}
			}
		}

		p {
			font-size: 1.2rem;
		}

		img {
			justify-self: right;
		}
	}

	&__footer {
		background-color: $block-color;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		.each {
			padding: 1rem;
			padding: 1rem;
			display: grid;
			align-items: center;
			justify-items: start;
		}
	}
}
