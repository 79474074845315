@import '../../config.scss';

.deliveryAssign {
	&__container {
		@include container;
	}

	&__temp {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		margin-bottom: 1.5rem;
		li {
			border: 1px solid darkblue;
			list-style-type: none;
			padding: 0.4rem;
			border-radius: 6px;
			font-size: large;
		}
	}

	&__area {
		margin-bottom: 2rem;
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;

		p {
			padding: 0.4rem;
			background-color: white;
			border-radius: 10px;
			border: 1px solid #ddd;
		}

		.active {
			background-color: #3d075c;
			color: white;
			fill: invert($color: #000000);
			animation: active 0.4s ease-in-out forwards;
		}

		@keyframes active {
			0%,
			100% {
				transform: scale(1);
			}
			50% {
				transform: scale(1.15);
			}
		}
	}

	&__assign {
		display: flex;
		align-items: center;

		flex-wrap: wrap;

		&__each {
			flex: 1 1 300px;
			margin: 0 0.5rem;
			display: flex;
			align-items: center;

			&__title {
				font-size: large;
				transform: translateY(-1.5rem);
				margin-right: 0.6rem;
				flex-basis: 50px;
			}

			&__select {
				flex: 1;

				.date {
					border: 1px solid #d9d9d9;
					padding: 0.1rem 0;
				}
			}
		}

		.btn {
			display: flex;
			transform: translateY(-1.5rem);
			justify-content: center;
			:first-child {
				width: 50%;
			}
		}
	}

	&__brandTable {
		display: none;
		@include respond-to(lg) {
			display: block;
		}

		a {
			color: rgb(4, 36, 46) !important;
		}
	}

	&__brandCard {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;
		perspective: 900px;

		@include respond-to(lg) {
			display: none;
		}

		a {
			color: rgb(4, 36, 46) !important;
		}
	}

	&__search {
		margin-bottom: 3rem;
	}
}

// .red {
// 	color: #ff575785;
// 	color: #ff858575;
// }
// .yellow {
// 	color: #ffc70e;
// 	color: #fcff49;
// }
// .blue {
// 	color: #0062a88f;
// 	color: #b9dfffa6;
// }
