.layout {
	position: relative;
	margin-bottom: 6rem;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__title {
		margin-bottom: 1rem;

		span {
			font-weight: 300;
			font-size: medium;
			text-transform: capitalize;
		}
	}

	.progress {
		position: fixed;
		right: 0;
		left: 0;
		top: 70px;
	}
	.keyValue{
		padding: 3px 0;
	}
	.keyValue h5{
		font-size: 17px;
		margin: 8px 0;
	}
	.keyValue p{
		font-size: 17px;
		margin: 8px 0;
	}
}
