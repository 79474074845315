.deliveryFeeChart {
	margin-top: 20px;
	gap: 2rem;

	$tb-color: #dddddd56;

	table {
		width: 100%;
		min-height: 40vh;
		border-collapse: collapse;
		letter-spacing: 1px;
		th,
		td {
			height: 5rem;
			padding: 15px;
			text-align: left;
			border: 1px solid #adadad81;
		}

		thead {
			background-color: $tb-color;
		}

		input {
			padding: 0.5rem;
			max-width: 60px;
			margin: 0.2rem;
			background-color: $tb-color;
			outline: none;
			border: none;
			border-radius: 6px;
			font-size: 1rem;
		}

		.error {
			background-color: #ff00009d;
		}
	}
	button {
		text-transform: inherit;
		font-size: 1rem;
	}
}
