$height: 400px;
$width: 300px;
$border-color: #1614143a;

.notification {
	position: absolute;
	top: 70px;
	right: 50px;
	z-index: 100;
	width: 0;
	max-height: $height;
	background-color: white;
	border: 0.0001rem solid $border-color;
	padding: 1rem;
	animation: notify 0.1s ease-out forwards;

	&__main {
		padding: 0.4rem;
		height: 100%;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 0;
		}
	}

	&::after {
		content: '';
		position: absolute;
		// z-index: 0;
		top: -10px;
		right: 40px;
		width: 20px;
		height: 20px;
		background-color: white;
		border: 0.0001rem solid $border-color;
		transform: rotateZ(45deg);
		border-bottom: 0;
		border-right: 0;
	}

	&__each {
		padding: 1rem 0;
		display: flex;
		gap: 1rem;
		border-bottom: 0.0001rem solid $border-color;
		&:first-child {
			padding-top: 0;
		}
	}

	&__title {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 0.5rem;

		p {
			color: #383737fa;
		}
	}

	&__body {
		p {
			color: #585656fa;
		}
	}

	@keyframes notify {
		from {
			opacity: 0;
			height: 0;
			width: 250px;
		}
		to {
			opacity: 1;
			height: $height;
			width: $width;
		}
	}
}

.close {
	animation: close 0.15s ease-out forwards;

	@keyframes close {
		from {
			opacity: 1;
			height: $height;
			width: $width;
		}
		to {
			height: 200px;
			width: 290px;
			opacity: 0;
		}
	}
}
