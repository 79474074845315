@import '../../config.scss';

.newPayment {
	&__container {
		@include container;
		min-height: auto;
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;
	}

	&__cards {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		gap: 2rem;
		min-height: 20vh;
		.each {
			padding: 1rem;
			display: grid;
			gap: 1rem;
			align-items: center;
			justify-items: start;
		}
	}

	&__select {
		padding: 1rem;
		background-color: $block-color;

		p {
			margin: 1rem 0;
			color: darkcyan;
			padding: 0 0.5rem;
		}

		&--each {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-start;
		}
	}

	&__remarks {
		padding: 1rem;
		background-color: $block-color;
		height: 25vh;
		textarea {
			background-color: transparent;
			padding: 1rem;
			width: 100%;
			height: 100%;
			outline: none;
			border: none;
		}
	}
}
