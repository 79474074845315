@import '../../config.scss';

.allOrders {
	&__container {
		@include container;
	}

	&__search {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin: 1rem 0;
		gap: 1rem;

		.🌏 {
			width: 230px;
			height: 40px;
		}

		.select {
			width: 230px;
			transform: scaleY(1.07);
			z-index: 10;
		}

		.checkbox {
			border-radius: 6px;
			border: 1px solid #c0bfbf;
			padding: 0 0.3rem;
		}
	}

	&__table {
		min-width: 1200px;
		background-color: white;
	}

	&__header {
		@include table-head-row;
	}

	&__body {
		@include table-body-row;
		@include tag;

		p {
			color: #585858;
			line-height: 1.4rem;
		}
	}

	&__date {
		position: relative;
	}

	&__datePicker {
		position: absolute;
		right: -250px;
		z-index: 100;

		@include respond-to(md) {
			right: 0;
			left: 0;
		}
	}

	&__pagination {
		padding: 2rem 0;
		display: flex;
		justify-content: flex-end;
	}
}
