@import '../../config.scss';

.riderProfile {
	&__container {
		@include container;
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;
	}

	&__header {
		gap: 0.5rem;
		height: 80px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
		border-bottom: 1px solid teal;
		h1 {
			transform: translateY(11px);
			color: #014242;
		}
	}

	&__stats {
		display: grid;
		gap: 1rem;
		grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
		perspective: 900px;
		&Item {
			transform: translateX(-10px);
			padding: 1rem;
			border: 1px solid #0000001a;
			background: linear-gradient(-45deg, #0b6d6d31, transparent);
		}
	}

	&__table {
		margin-top: 2rem;
		min-width: 1200px;
		background-color: white;
		&Header {
			@include table-head-row;
		}
	}

	&__body {
		@include table-body-row;

		@include tag;

		p {
			color: #585858;
		}
	}

	&__date {
		position: relative;
	}

	&__datePicker {
		position: absolute;
		right: -200px;
		z-index: 100;

		@include respond-to(md) {
			right: 100px;
		}
	}
}
