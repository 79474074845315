// font Roboto

@import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2&family=Roboto:wght@400&display=swap');

// vairables

$bg-color: #f7f8fc;

$pick-color: #f6f6f6;

$tag-color: #bdf7bd9d;

$link-color: #32ad32;

$block-color: #f3f3f393;

$dark-color: #000;

// breakpoints

$breakpoints: (
	sm: 600px,
	md: 960px,
	lg: 1280px,
);

@mixin respond-to($breakpoint) {
	@if map-has-key($breakpoints, $key: $breakpoint) {
		@media (min-width: #{map-get($breakpoints, $breakpoint)}) {
			@content;
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
	}
}

// containers

@mixin container {
	min-height: 80vh;
	padding: 2rem;
	background-color: white;
}

// table row mixins

@mixin table-head-row {
	background-color: $pick-color;
	padding: 1.5rem 1rem;
	display: grid;
	align-items: center;
	gap: 1rem;
}

@mixin table-body-row {
	padding: 1.5rem 1rem;
	display: grid;
	align-items: flex-start;
	border-top: 1px solid #ddd;
	gap: 1rem;

	p {
		font-size: 0.9rem;
		line-height: 1.2rem;
		text-align: start;
		text-transform: capitalize;
	}

	a {
		font-size: 1.2rem;
		color: $link-color;
	}

	span {
		width: 20px;
		height: 20px;
		padding: 0.4rem;
		border-radius: 50%;
		background-color: $tag-color;

		@include respond-to(lg) {
			background-color: transparent;
		}
	}

	& > * {
		max-width: 200px;
	}
}

// bg-green rounded

@mixin Tag {
	width: 20px;
	height: 20px;
	padding: 0.4rem;
	border-radius: 50%;
	background-color: $tag-color;
}

@mixin tag {
	.tag {
		min-width: 100px;
		border-radius: 6px;
		padding: 0.5rem;
		color: white;
		text-align: center;
		width: 60%;
		line-height: 1rem;
		margin-bottom: 1rem;
	}
}

// typography

$typoArray: (
	(xs, 0.75),
	(sm, 0.875),
	(base, 1),
	(lg, 1.125),
	(xl, 1.25),
	(2xl, 1.5),
	(3xl, 1.875),
	(4xl, 2.25),
	(5xl, 3),
	(6xl, 4)
);

@each $size, $value in $typoArray {
	.text-#{$size} {
		font-size: #{$value}rem;
	}
}

// shadows

$shadowArray: (
	(sm, 0px 1px 2px),
	(md, 0px 4px 6px),
	(lg, 0px 10px 15px),
	(xl, 0px 20px 25px),
	(2xl, 0px 25px 50px),
	(inner, 0px 2px 4px)
);

@each $size, $value in $shadowArray {
	@if ($size == inner) {
		.shadow-#{$size} {
			box-shadow: inset $value rgba(0, 0, 0, 0.06);
		}
	} @else {
		.shadow-#{$size} {
			box-shadow: $value rgba(0, 0, 0, 0.09);
		}
	}
}
