@import "../../config.scss";

.merchantLayout {
  background-color: white;
  header {
    border-bottom: 1px solid #b9b9b973;
    padding: 1.5rem 2rem;
  }

  .main {
    padding: 2rem;

    p {
      color: grey;
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 768px) {
    .main {
      padding: .8rem;
    }
  }
}
