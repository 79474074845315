@import '../../config.scss';

.login {
	form {
		width: 330px;
		max-width: 100%;
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;
	}
}
