@import '../../config.scss';

.pickTable {
	&__header {
		@include table-head-row;
		&Col {
			h4 {
				font-size: medium;
				font-weight: 500;
			}
		}
	}

	&__body {
		&Main {
			@include table-body-row;
		}

		.tag {
			span {
				@include Tag;
			}
		}

		.icon {
			transform: translateY(-12px);
		}

		.select {
			transform: translateY(-18px);
			transition: 0.2s ease-in-out;
		}
	}
}
