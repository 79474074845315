@import '../../config.scss';

$width: 100px;
$b-color: #22222270;

.bulk {
	padding: 2rem;

	header {
		h2 {
			padding: 2rem 0;
		}
	}

	&__table {
		background-color: white;
		padding: 1rem;
		.main {
			overflow: scroll;
			height: 60vh;
		}

		table,
		td,
		th {
			border: 1px solid #ccc9c9;
			font-size: 0.8rem;
		}

		table {
			border-collapse: separate;
			border-spacing: 0px;
			width: 2200px;
		}

		thead tr > th {
			position: sticky;
			z-index: 2;
			top: 0;
			background: linear-gradient(to bottom, black, rgb(3, 47, 58));
			color: white;
		}

		thead tr > :first-child {
			z-index: 1100;
			left: 0;
			top: 0;
		}

		td {
			text-align: center;
			padding: 0.2rem;
			font-weight: 200;
			font-size: x-small;
			&:focus-within {
				outline: 2px solid #024444;
			}
		}

		.no {
			width: 30px;
		}

		tr {
			.estimated {
				max-width: 150px;
			}

			.amount {
				width: 110px;
			}

			.name {
				width: 160px;
			}

			.moid {
				width: 130px;
			}

			.phone {
				width: 160px;
			}

			.outside,
			.estimated {
				width: 90px;
			}

			.weight {
				width: 100px;
			}

			.payment,
			.delivery {
				width: 180px;
			}

			.parcel,
			.delete {
				width: 100px;
			}
			.note {
				width: 140px;
			}

			.area {
				width: 160px;
			}

			.address {
				width: 200px;
			}

			.cross {
				background: #ddd;
			}
		}

		.alternate {
			background-color: #f2f2f2;
			input,
			textarea {
				background-color: #f2f2f2;
			}
			&:hover {
				background-color: #dddddd5b;
			}
		}

		tbody tr > :first-child {
			background: #ddd;
			position: sticky;
			z-index: 1000;
			left: 0;
		}
	}

	.custom {
		height: 100%;
		width: 100%;
		position: relative;

		input,
		textarea {
			cursor: pointer;
			text-align: center;
			border: none;
			width: 100%;
			padding: 0.3rem;
			outline: none;
			font-size: 1rem;
			&:focus {
				cursor: no-drop;
			}
			&:disabled {
				cursor: inherit;
				background-color: white;
			}
		}

		input {
			height: 100px;
		}

		textarea {
			&::placeholder {
				transform: translate3d(0, 28px, 0);
			}
			// height: 40px;
		}

		span {
			$err-color: #fde5e5;
			position: absolute;
			animation: pop 0.2s ease-in-out forwards;
			top: -12px;
			left: 30%;
			z-index: 1;
			padding: 0.5rem;
			background-color: $err-color;
			color: rgb(95, 7, 7);
			line-height: 1.4rem;
			border-radius: 6px;
			border: 1px solid $b-color;

			&::after {
				content: '';
				position: absolute;
				bottom: -11px;
				right: 40px;
				width: 20px;
				height: 20px;
				background-color: $err-color;
				border: 0.0001rem solid $b-color;
				transform: rotateZ(-45deg);
				border-top: 0;
				border-right: 0;
			}
		}

		textarea + * {
			top: -12.5px;
			left: 55%;
		}

		@keyframes pop {
			from {
				opacity: 0;
				transform: scale(0);
			}
			to {
				opacity: 1;
				transform: scale(1);
			}
		}
	}
}
